import "../../css/education/education-page.css";
import Intro from "./Intro";
import ObjectiveSection from "./ObjectiveSection";
import RoadmapSection from "./RoadmapSection";
import ContactForm from "../../components/ContactForm";
import Footer from "../../components/Footer";
import Opinions from "../../components/Opinions";
import UserOpinion from "../home/components/UserOpinion";

export default function EducationPage() {
	const opinions = [
		{
			name: "Marlene Olivera",
			country: "CONSAR",
		},
		{
			name: "Jesús Castañeda",
			country: "Secretaría de educación de Veracruz",
		},
		{
			name: "Erika Sánchez",
			country: "CREDIFONDO Bolivia",
		},
		{
			name: "Jesús Castañeda",
			country: "Secretaría de educación de Veracruz",
		},
		{
			name: "Erika Sánchez",
			country: "CREDIFONDO Bolivia",
		},
	];
	return (
		<>
			<Intro></Intro>
			{/* <Opinions
				title="Construyen educación financiera con nostros"
				card="UserOpinion"
				items={opinions.map((item) => (
					<UserOpinion
						name={item.name}
						country={item.country}
					></UserOpinion>
				))}
			></Opinions> */}
			<ObjectiveSection></ObjectiveSection>
			<RoadmapSection></RoadmapSection>
			<ContactForm
				title="¿Quieres trabajar con Alfi en Educación financiera?"
				description="Llevamos educación e inclusión financiera a todo el mundo. Contáctanos para trabajar juntos."
			/>
			<Footer />
		</>
	);
}
