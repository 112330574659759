import { Modal } from "react-bootstrap";
import bearThanks from "../../assets/images/bear-thanks.png";
import "../../css/components/no-back-modal.css";

export default function NoBackModal(props) {
	return (
		<div>
			<Modal
				{...props}
				size="md"
				centered
				className="modal"
				contentClassName="noback-content"
			>
				<Modal.Body className="noback-body">
					<img src={bearThanks} alt="Agradecimiento" />
					{/* <img src={images.thanksBear} alt="Agradecimiento" /> */}
					<div className="thanks-message">
						<h6>¡Gracias!</h6>
						<div>Pronto nos pondremos en contacto</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
