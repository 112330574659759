export const images = {
	//Alfi
	alfiGreen: "https://alfi-images.s3.us-east-2.amazonaws.com/ALFIVERDE.svg",
	alfiWhite: "https://alfi-images.s3.us-east-2.amazonaws.com/ALFIBLANCO.svg",

	//Team
	bear: "https://alfi-images.s3.us-east-2.amazonaws.com/bearImg.png",
	coolBull: "https://alfi-images.s3.us-east-2.amazonaws.com/bullCoolImg.png",
	alfiTeam: "https://alfi-images.s3.us-east-2.amazonaws.com/animalsImg.png",
	alfiTeam2: "https://alfi-images.s3.us-east-2.amazonaws.com/SALUDOS.svg",
	alfiTeamMobile:
		"https://alfi-images.s3.us-east-2.amazonaws.com/team-alfi.png",
	laptopTiger: "https://alfi-images.s3.us-east-2.amazonaws.com/tigerImg.png",
	laptopTiger2:
		"https://alfi-images.s3.us-east-2.amazonaws.com/TIGRELAPTOP.svg",
	moneyBull:
		"https://alfi-images.s3.us-east-2.amazonaws.com/bullMoneyImg.png",
	newsBull: "https://alfi-images.s3.us-east-2.amazonaws.com/bullNews.png",
	smartBear:
		"https://alfi-images.s3.us-east-2.amazonaws.com/smartBearImg.png",
	smartBear2: "https://alfi-images.s3.us-east-2.amazonaws.com/OSOLENTES.svg",
	thanksBear:
		"https://alfi-images.s3.us-east-2.amazonaws.com/thanksBearImg.png",
	thanksTiger: "https://alfi-images.s3.us-east-2.amazonaws.com/TIGREFORM.svg",
	// Icons
	mailIcon: "https://alfi-images.s3.us-east-2.amazonaws.com/mailIcon.png",
	acumulatedFound:
		"https://alfi-images.s3.us-east-2.amazonaws.com/accumulatedFundIcon.png",
	boyIdea: "https://alfi-images.s3.us-east-2.amazonaws.com/boyImg.png",
	boyOportunity:
		"https://alfi-images.s3.us-east-2.amazonaws.com/opportunityIcon.png",
	girlLaptop:
		"https://alfi-images.s3.us-east-2.amazonaws.com/personalizedIcon.png",
	girlRead: "https://alfi-images.s3.us-east-2.amazonaws.com/girlImg.png",
	girlThink: "https://alfi-images.s3.us-east-2.amazonaws.com/problemIcon.png",
	cup: "https://alfi-images.s3.us-east-2.amazonaws.com/competitionIcon.png",
	costIncrement:
		"https://alfi-images.s3.us-east-2.amazonaws.com/costEffectivenessIcon.png",
	tasks: "https://alfi-images.s3.us-east-2.amazonaws.com/practiceIcon.png",
	star: "https://alfi-images.s3.us-east-2.amazonaws.com/starIcon.png",
	burgerIcon:
		"https://alfi-images.s3.us-east-2.amazonaws.com/burgerMenuLandingIcon.png",
	// Stores
	appleStore:
		"https://alfi-images.s3.us-east-2.amazonaws.com/appStoreImg.png",
	playStore:
		"https://alfi-images.s3.us-east-2.amazonaws.com/googlePlayImg.png",
	appleStoreGray:
		"https://alfi-images.s3.us-east-2.amazonaws.com/appStoreImgGray.png",
	playStoreGray:
		"https://alfi-images.s3.us-east-2.amazonaws.com/googlePlayImgGray.png",
	// Partners colors
	etoro: "https://alfi-images.s3.us-east-2.amazonaws.com/etoroLogo.png",
	sura: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Grupo_Sura_logo.svg/2560px-Grupo_Sura_logo.svg.png",
	credifondo:
		"https://alfi-images.s3.us-east-2.amazonaws.com/credifondoLogo.png",
	fintual:
		"https://play-lh.googleusercontent.com/Cv-LljyItYsLMirPciKOAIt0ZzX8VGlSXRpCdlkSKkW95OxGsAPQlDWU0sdq-JwpF7s",
	bbva: "https://dx23yqi1tewca.cloudfront.net/images/poiLogo/b6b9969c-e85e-425f-abf3-e371413b4c79.png",
	davivienda:
		"https://upload.wikimedia.org/wikipedia/en/thumb/b/b1/Davivienda_logo.svg/1200px-Davivienda_logo.svg.png",
	coppel: "https://i1.wp.com/contigolarevista.com/wp-content/uploads/2020/12/Coppel_logo.png",
	cumplo: "https://alfi-images.s3.us-east-2.amazonaws.com/cumploLogo.png",
	creditea:
		"https://www.creditea.mx/mx/wp-content/uploads/sites/6/2017/04/creditea_logo_563x180.png",
	//Partners gray
	bbvaGray: "https://alfi-images.s3.us-east-2.amazonaws.com/bbvaLogoGray.png",
	consarGray:
		"https://alfi-images.s3.us-east-2.amazonaws.com/consarLogoGray.png",
	credifondoGray:
		"https://alfi-images.s3.us-east-2.amazonaws.com/credifondoLogoGray.png",
	crediteaGray:
		"https://alfi-images.s3.us-east-2.amazonaws.com/crediteaLogoGray.png",
	cumploGray:
		"https://alfi-images.s3.us-east-2.amazonaws.com/cumploLogoGray.png",
	networthGray:
		"https://alfi-images.s3.us-east-2.amazonaws.com/networthLogoGray.png",
	sevGray: "https://alfi-images.s3.us-east-2.amazonaws.com/sevLogoGray.png",
	suraGray: "https://alfi-images.s3.us-east-2.amazonaws.com/suraLogoGray.png",

	//Social media
	instagram:
		"https://alfi-images.s3.us-east-2.amazonaws.com/intagramIconWhite.png",
	youtube:
		"https://alfi-images.s3.us-east-2.amazonaws.com/youtubeIconWhite.png",
	facebook: "https://alfi-images.s3.us-east-2.amazonaws.com/facebook.png",
	tiktok: "https://alfi-images.s3.us-east-2.amazonaws.com/tiktok.png",
	twitter: "https://alfi-images.s3.us-east-2.amazonaws.com/twitter.png",

	//Places
	firstPlace:
		"https://alfi-images.s3.us-east-2.amazonaws.com/placeOneIcon.png",
	secondPlace:
		"https://alfi-images.s3.us-east-2.amazonaws.com/placeTwoIcon.png",
	thirdPlace:
		"https://alfi-images.s3.us-east-2.amazonaws.com/placeThreeIcon.png",

	//Flats
	argentina: "https://alfi-images.s3.us-east-2.amazonaws.com/argentina.png",

	//Background
	bg: "https://alfi-images.s3.us-east-2.amazonaws.com/LandingBackground.png",
};
