import "../../css/education/education-page.css";
import FilledButton from "../../components/FilledButton";
import { images } from "../../assets/images/images";

export default function Intro() {
	return (
		<div className="education-intro">
			<div className="info">
				<h1>Construimos un mejor Futuro Financiero</h1>
				<div>
					<b> Empresa | Gobierno | Instituciones | Docentes</b>
				</div>
				<div>
					Promovemos salud financiera a través de un aprendizaje
					simple y continua.
				</div>
				<div>
					<FilledButton
						text="Promovamos aprendizaje"
						url="https://alfi.la/"
					/>
				</div>
			</div>
			<div></div>
			<img src={images.moneyBull} alt="Toro invest" />
		</div>
	);
}
