import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { images } from "../../assets/images/images";

export default function ObjectiveSection() {
	const [seconds, setSeconds] = useState(0);

	// useEffect(() => {
	// 	const counter = () => {
	// 		setSeconds((seconds) => seconds + 1);
	// 		console.log("Second:", seconds);
	// 		console.log("Interval:", interval);
	// 		if (seconds >= 5) {
	// 			clearInterval(interval);
	// 		}
	// 	};
	// 	const interval = setInterval(counter, 1000);
	// 	// return () => clearInterval(interval);
	// }, []);

	return (
		<div>
			<div className="objectives-section">
				<h2>Ayudamos a convertir metas financieras en realidad</h2>
				<p>
					Alfi es la <b> plataforma gratuita </b> de aprendizaje
					financiero basada en gamificación y decisiones reales
					adaptadas a las personas. Conecta <b> educación </b>
					financiera, <b> diversión </b> y<b> productos </b> a través
					de las herramientas financieras que Alfi te otorga.
				</p>

				<div className="fact-card">
					<div className="card-img">
						<div className="photo">
							<img src={images.girlThink} alt="" />
						</div>
					</div>
					<div className="title">
						<div>Problema</div>
						{/* <button onClick={() => interval(10)}>press</button> */}
						<div className="figure">
							<CountUp end={65} duration={1.5} />%
						</div>
					</div>
					<p>
						De las personas en Latinoamérica, de acuerdo a la OCDE,
						son consideradas analfabetas financieras.
					</p>
				</div>
				<h3 className="impact">
					+ de 70 000 usuarios en Latinoamérica aprenden finanzas en
					Alfi
				</h3>
			</div>
		</div>
	);
}
