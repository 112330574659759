import money from "../../../assets/icons/money.png";
import "../../../css/home/home-section.css";

export default function InfoCard(props) {
	return (
		<div className="item">
			<img src={money} alt="" />
			<div className="item-info">
				<div>{props.title}</div>
				<b>{props.subtitle}</b>
			</div>
		</div>
	);
}
