import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./css/components/navbar.css";
import Navigationbar from "./components/Navbar";
import AboutPage from "./pages/about/AboutPage";
import EducationPage from "./pages/education/EducationPage";
import HomePage from "./pages/home/HomeSection";
import MarketPage from "./pages/marketplace/MarketPage";
import HelperButton from "./components/HelperButton";

function App() {
	return (
		<>
			<Router>
				<Navigationbar />

				<HelperButton />

				<Switch>
					<Route exact path="/" component={HomePage}></Route>
					<Route
						path="/educacion-financiera"
						component={EducationPage}
					></Route>
					<Route path="/marketplace" component={MarketPage}></Route>
					<Route path="/acerca-de" component={AboutPage}></Route>
				</Switch>
			</Router>
		</>
	);
}

export default App;
