import React from "react";
import FilledButton from "../../../components/FilledButton";
import "../../../css/home/invitation.css";
import { images } from "../../../assets/images/images";

export default function Invitation3() {
	return (
		<div className="invitation3">
			<h3>
				Nuestro propósito es que logres hábitos financieros saludables.
				Haz que tus decisiones cuenten y alcanza tus metas.
			</h3>
			<div>
				<FilledButton
					text="Juega y conoce más"
					url="https://alfi.la/"
				/>
			</div>

			<img src={images.alfiTeam2} alt="" />
		</div>
	);
}
