import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LfLbJQfAAAAABlpBH4AK-gZE2uyxX30ESs0PqAJ">
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);
