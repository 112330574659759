import { useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink } from "react-router-dom";
import FilledButton from "./FilledButton";
import { images } from "../assets/images/images";
import SocialMedia from "./SocialMedia";
import "../css/components/navbar.css";

const BurgerMenu = ({ ...props }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const toggleShow = () => setShow((s) => !s);
	return (
		<>
			{/* <Navbar.Toggle aria-controls="offcanvasNavbar" /> */}
			<Button className="burger-icon" onClick={toggleShow}>
				<img src={images.burgerIcon} alt="Burger Icon" />
			</Button>
			<Navbar.Offcanvas
				id="offcanvasNavbar"
				// aria-labelledby="offcanvasNavbarLabel"
				show={show}
				onHide={handleClose}
				placement="start"
				className="sidebar-items"
			>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title id="offcanvasNavbarLabel">
						{/* <NavLink to="/" className="nav-logo">
							<img src={images.alfiGreen} alt="Logo Alfi" />
						</NavLink> */}
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Nav
						className="justify-content-end flex-grow-1 pe-3"
						onClick={handleClose}
					>
						<NavLink
							exact
							to="/"
							activeClassName="nav-item-active"
							className="nav-item"
						>
							Inicio
						</NavLink>
						<NavLink
							activeClassName="nav-item-active"
							className="nav-item"
							to="/educacion-financiera"
						>
							Educación financiera
						</NavLink>
						{/* <NavLink
							activeClassName="nav-item-active"
							className="nav-item"
							to="/marketplace"
						>
							Marketplace
						</NavLink>
						<NavLink
							activeClassName="nav-item-active"
							className="nav-item"
							to="/acerca-de"
						>
							Acerca de
						</NavLink> */}
					</Nav>
					<SocialMedia></SocialMedia>
				</Offcanvas.Body>
			</Navbar.Offcanvas>
		</>
	);
};

const NavigationBar = () => {
	return (
		<>
			{/* <Navbar bg="dark" expand="lg">
				<Container>
					<Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							<Nav.Link href="#home">Home</Nav.Link>
							<Nav.Link href="#link">Link</Nav.Link>
							<NavDropdown
								title="Dropdown"
								id="basic-nav-dropdown"
							>
								<NavDropdown.Item href="#action/3.1">
									Action
								</NavDropdown.Item>
								<NavDropdown.Item href="#action/3.2">
									Another action
								</NavDropdown.Item>
								<NavDropdown.Item href="#action/3.3">
									Something
								</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href="#action/3.4">
									Separated link
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar> */}
			<Navbar className="nav" expand="lg">
				{/* <Container fluid> */}
				<BurgerMenu />

				<Navbar.Brand className="nav-brand">
					<NavLink to="/">
						<img src={images.alfiGreen} alt="Logo Alfi" />
					</NavLink>
				</Navbar.Brand>
				{/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
				<Navbar.Collapse
					className="navbar-collapse"
					id="basic-navbar-nav"
				>
					<Nav className="me-auto nav-main">
						<NavLink
							exact
							to="/"
							activeClassName="nav-item-active"
							className="nav-item"
						>
							Inicio
						</NavLink>
						<NavLink
							activeClassName="nav-item-active"
							className="nav-item"
							to="/educacion-financiera"
						>
							Educación financiera
						</NavLink>
						{/* <NavLink
							activeClassName="nav-item-active"
							className="nav-item"
							to="/marketplace"
						>
							Marketplace
						</NavLink>
						<NavLink
							activeClassName="nav-item-active"
							className="nav-item"
							to="/acerca-de"
						>
							Acerca de
						</NavLink> */}
						<SocialMedia></SocialMedia>
					</Nav>
				</Navbar.Collapse>
				<div className="action-buttons">
					{/* <Button variant="success" className="play-btn">
							Registrarse
						</Button> */}
					<FilledButton
						text="Jugar"
						onClick={() =>
							(window.location.href = "https://alfi.la/")
						}
					/>
				</div>
				{/* </Container> */}
			</Navbar>
		</>
	);
};

export default NavigationBar;
