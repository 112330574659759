import FilledButton from "../../../components/FilledButton";
import { images } from "../../../assets/images/images";
import "../../../css/home/invitation.css";

export default function Invitation2() {
	return (
		<div className="invitation">
			<img
				className="invitation-img-md"
				src={images.smartBear2}
				alt="Bear take notes"
			/>
			<div className="invitation-info info-right">
				<h2>Aprende a desarrollar tus conocimientos</h2>
				<div>
					Aplica tus <b> conceptos financieros </b>y controla tus
					finanzas de manera simple mientras te diviertes.
				</div>
				<div>
					<FilledButton
						text="Desarrolla tus conocimientos"
						url="https://alfi.la/"
					></FilledButton>
				</div>
			</div>
		</div>
	);
}
