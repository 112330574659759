import { useState } from "react";
import FilledButton from "../../components/FilledButton";
import "../../css/home/market-section.css";
import { images } from "../../assets/images/images";

export default function MarketSection() {
  const [type, setType] = useState("Inversión");
  const [isActive, setIsActive] = useState(false);

  const partnersLogos = () => {
    switch (type) {
      case "Inversión":
        return [images.sura, images.credifondo /*, images.fintual*/];
      case "Ahorro":
        return [images.bbva, images.davivienda, images.coppel];
      case "Crédito":
        return [images.creditea, images.cumplo];
    }
  };

  const changeType = (newType) => {
    setType(newType);
    setIsActive(true);
  };

  return (
    <div className="market_section">
      <div className="info">
        <h2>Descubre los mejores productos financieros</h2>
        <p>
          Empieza a rentabilizar tu dinero.
          <b> Ahorra e invierte </b> en los mejores productos seleccionados para
          ti y construye tu futuro financiero.
        </p>
        <div className="partners">
          <ul className="types">
            {["Inversión", "Ahorro", "Crédito"].map((t) => (
              <a
                className={`market-type ${type == t ? "activeType" : ""}`}
                onClick={() => changeType(t)}
              >
                {t}
              </a>
            ))}
          </ul>
          <div className="logos">
            {partnersLogos().map((logo) => (
              // <li key={logo}>
              <div className="wrapper-logo">
                <img src={logo} alt="partner image" />
              </div>
              // </li>
            ))}
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <FilledButton
            text="Entra al mundo financiero"
            url="https://alfi.la/"
          />
        </div>
      </div>
      <img src={images.laptopTiger2} alt="Tiger laptop" />
    </div>
  );
}
