import { images } from "../../assets/images/images";
import FeatureCard from "./components/FeatureCard";

export default function RoadmapSection() {
	return (
		<div className="roadmap-section">
			<h2>Promovemos el aprendizaje</h2>
			<div className="features">
				<FeatureCard
					icon={images.girlLaptop}
					title="Personalizado"
					description="Identifica tus intereses, tus motivaciones y tu perfil de inversor: Conservador, Moderado y Agresivo."
				/>
				<FeatureCard
					icon={images.tasks}
					title="Práctico"
					description="Reconocer las decisiones a las que te enfrentas en tu día a día y que afectan tus finanzas."
				/>
				<FeatureCard
					icon={images.cup}
					title="Competitivo"
					description="Compite con tus amigos o todo el mundo, entrena cuantas veces lo desees y visualiza tus progresos financieros."
				/>
				<FeatureCard
					icon={images.boyOportunity}
					title="Oportuno"
					description="Impulsa tus skills financierons y conéctalos con los mejores productos."
				/>
			</div>
		</div>
	);
}
