import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import RankingCard from "./components/RankingCard";
import "../../css/home/ranking-section.css";

export default function RankingSection() {
	return (
		<div className="ranking-section">
			<div className="text">
				<h1>Aprender finanzas no tiene por qué ser aburrido</h1>
				<p>
					Compite y demuestra tus skills financieros con personas de
					todo el mundo
				</p>
			</div>
			<div className="ranking">
				{window.innerWidth > 992 ? (
					<DeskRanking></DeskRanking>
				) : (
					<MobileRanking> </MobileRanking>
				)}
			</div>
		</div>
	);
}

const DeskRanking = () => {
	return (
		<>
			<div className="ranking-desk">
				<RankingCard
					username="Oscar Giraldo"
					nickname="@OscarG"
					place="second"
					photo="https://alfi-images.s3.us-east-2.amazonaws.com/OSCARGIRALDO.jpeg"
				></RankingCard>
				<RankingCard
					username="Victor Cueva"
					nickname="@VictorCueva"
					place="first"
					photo="https://alfi-images.s3.us-east-2.amazonaws.com/Victor+Cueva+.png"
				></RankingCard>
				<RankingCard
					username="Mirella Guevara"
					nickname="@MirellaG"
					place="third"
					photo="https://alfi-images.s3.us-east-2.amazonaws.com/Mirella+Guevara.png
					"
				></RankingCard>
			</div>
		</>
	);
};

const MobileRanking = () => {
	return (
		<>
			<Carousel
				showArrows={false}
				showStatus={false}
				autoPlay={false}
				showThumbs={false}
			>
				<RankingCard
					username="Victor Cueva"
					nickname="@VictorCueva"
					place="first"
					photo="https://alfi-images.s3.us-east-2.amazonaws.com/Victor+Cueva+.png"
				></RankingCard>
				<RankingCard
					username="Oscar Giraldo"
					nickname="@OscarG"
					place="second"
					photo="https://alfi-images.s3.us-east-2.amazonaws.com/OSCARGIRALDO.jpeg"
				></RankingCard>
				<RankingCard
					username="Mirella Guevara"
					nickname="@MirellaG"
					place="third"
					photo="https://alfi-images.s3.us-east-2.amazonaws.com/Mirella+Guevara.png
					"
				></RankingCard>
			</Carousel>
		</>
	);
};
