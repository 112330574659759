import { Button, Link } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../css/components/filled-button.css";

export default function FilledButton(props) {
	return (
		<a href={props.url}>
			<Button className="filled-button" onClick={props.onClick}>
				{props.text}
			</Button>
		</a>
	);
}
