import { Image, Container } from "react-bootstrap";
import FilledButton from "../../../components/FilledButton";
import { images } from "../../../assets/images/images";
import "../../../css/home/invitation.css";

export default function Invitation() {
	return (
		<Container className="invitation">
			<div>
				<div className="invitation-info">
					<h2>¡Juega, practica y toma las mejores decisiones!</h2>
					<div>
						Desarrolla tus habilidades con las AlfiCards. Construye
						tu futuro financiero con cada decisión y aumenta tus
						<b> ganancias y rentabilidad. </b>
					</div>
					<div>
						<FilledButton
							text="Juega y conoce más"
							url="https://alfi.la/"
						></FilledButton>
					</div>
				</div>
			</div>
			<div>
				<img src={images.newsBull} alt="Bear with phone" />
			</div>
		</Container>
	);
}
