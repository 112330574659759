import "../css/components/footer.css";
import { images } from "../assets/images/images";
import SocialMedia from "./SocialMedia";

export default function Footer() {
	return (
		<div className="footer">
			<div className="logo-wrapper">
				<img src={images.alfiWhite} alt="Logo Alfi" />
			</div>
			<div className="footer-links">
				<ul>
					<li>
						<a href="#">Acerca de nosotros </a>
					</li>
					<li>
						<a href="#">Preguntas frecuentes</a>
					</li>
					<li>
						<a href="#">Términos y condiciones </a>
					</li>
					<li>
						<a href="#">T&C promociones vigentes</a>
					</li>
					<li>
						<a href="#">Política de privacidad</a>
					</li>
				</ul>
			</div>
			<div className="footer-contact">
				<ul>
					<li>
						<a href="mailto: asegura@soyalfi.com">
							México: asegura@soyalfi.com
						</a>
					</li>
					<li>
						<a href="mailto: vmorales@soyalfi.com">
							Perú: vmorales@soyalfi.com
						</a>
					</li>
					<li>
						<a href="mailto: Carlos.Vargas@soyalfi.com">
							Chile: Carlos.Vargas@soyalfi.com
						</a>
					</li>
				</ul>
			</div>
			<div className="footer-social-media">
				<div className="stores">
					<a
						href="https://apps.apple.com/pe/app/alfi/id1497365055"
						target="_blank"
					>
						<img src={images.appleStore} alt="Apple Store" />
					</a>

					<a
						href="https://play.google.com/store/apps/details?id=com.alfinative&hl=es_PE&gl=US"
						target="_blank"
					>
						<img src={images.playStore} alt="Play Store" />
					</a>
					{/* <StoreButton
						type="apple"
						text="Download on the"
						store="App Store"
					/>
					<StoreButton text="Get it on" store="Google Play" /> */}
				</div>
				<SocialMedia></SocialMedia>
			</div>
		</div>
	);
}
