import { images } from "../../assets/images/images";
import "../../css/marketplace/marketplace.css";

export default function AboutPage() {
	return (
		<div className="marketplace-section">
			<h1>We are building this section</h1>
			<img src={images.alfiTeam} alt="" />
		</div>
	);
}
