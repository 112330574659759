import "../../css/home/home-section.css";
import IntroSection from "./IntroSection";
import Invitation from "./components/Invitation";
import RankingSection from "./RankingSection";
import Invitation2 from "./components/Invitation2";
import MarketSection from "./MarketSection";
import Invitation3 from "./components/Invitation3";
import ContactForm from "../../components/ContactForm";
import Footer from "../../components/Footer";
import Opinions from "../../components/Opinions";
import UserOpinion from "./components/UserOpinion";

const HomePage = () => {
	const opinions = [
		{
			name: "John Doe",
			country: "Mexico",
		},
		{
			name: "Sandra Peña",
			country: "Peru",
		},
		{
			name: "Elena del Aguila",
			country: "Colombia",
		},
		{
			name: "Oscar Garcia",
			country: "Venezuela",
		},
		{
			name: "Fernando Castro",
			country: "Mexico",
		},
	];

	return (
		<div>
			<IntroSection></IntroSection>
			{/* <Opinions
				title="¿Qué opinan de nosotros?"
				card="UserOpinion"
				items={opinions.map((item) => (
					<UserOpinion
						name={item.name}
						country={item.country}
					></UserOpinion>
				))}
			></Opinions> */}
			<Invitation></Invitation>
			<RankingSection></RankingSection>
			<Invitation2></Invitation2>
			<MarketSection></MarketSection>
			<Invitation3></Invitation3>
			<ContactForm title="¡Hola!, ¿Cómo podemos ayudarte?" />
			<Footer></Footer>
		</div>
	);
};

export default HomePage;
