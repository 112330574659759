import { CloseButton, Button, Table } from "react-bootstrap";
import user from "../../../assets/images/user.jpg";
import money from "../../../assets/icons/money-blue.png";
import flatIcon from "../../../assets/icons/flat.png";

function ColumnHeader(props) {
	return (
		<>
			<div>
				<img src={money} alt="Money icon" />
				<div>{props.name}</div>
				<div>
					<b> {props.value} </b>
				</div>
			</div>
		</>
	);
}

export default function RankingDetail(props) {
	return (
		<div className="ranking-detail">
			<div className="info-user">
				<img src={props.photo} alt="User photo" />
				<div>
					<div className="info_name">{props.username}</div>
					<div className="info_nickname">{props.nickname}</div>
					<img src={flatIcon} alt="" />
				</div>
			</div>
			<Table striped borderless hover>
				<thead>
					<tr>
						<td>
							<ColumnHeader
								name="Fondo acumulado"
								value="$ 15,222.76"
							/>
						</td>
						<td>
							<ColumnHeader
								name="Rentabilidad lograda"
								value="7000%"
							/>
						</td>
						<td>
							<ColumnHeader
								name="Rango de inversión"
								value="$ 500.000"
							/>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Netflix</td>
						<td>$500</td>
						<td>
							<div>
								$700.00 <br /> + $200.00
							</div>
						</td>
					</tr>
					<tr>
						<td>IBM</td>
						<td>$150</td>
						<td>
							<div>
								$500.00 <br /> + $400.00
							</div>
						</td>
					</tr>
					<tr>
						<td>Apple INC</td>
						<td>$150</td>
						<td>
							<div>
								$900.00 <br /> + $10.00
							</div>
						</td>
					</tr>
					<tr>
						<td>Avianca</td>
						<td>$150</td>
						<td>
							<div>
								$600.00 <br /> + $40.00
							</div>
						</td>
					</tr>
				</tbody>
			</Table>
		</div>
	);
}
