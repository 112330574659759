import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { images } from "../assets/images/images";
import NoBackModal from "./modals/NoBackModal";
import emailjs from "emailjs-com";
import "../css/components/contact-form.css";
import { useEffect } from "react";

export default function ContactForm(props) {
  const [modalShow, setModalShow] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
    if (e.target.message.value.length > 1) {
      emailjs
        .sendForm(
          "service_63ojl2l",
          "template_uzgb7pa",
          e.target,
          "user_lSYvN0uIj1qxyAix6Kl5P"
        )
        .then((res) => {
          console.log(res);
          e.target.name.value = "";
          e.target.email.value = "";
          e.target.message.value = "";
          setModalShow(true);
        })
        .catch((err) => console.log(err));
    } else {
      console.log("Error sending email...");
    }
  };
  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    } else {
      const tokenCaptcha = await executeRecaptcha();
      setToken(tokenCaptcha);
    }
  };
  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    if (!token) handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  return (
    <div id="contact-form" className="form-section ">
      <div className="contact-form" onSubmit={sendEmail}>
        <form action="#">
          <h2>{props.title}</h2>
          <div>{props.description}</div>
          <div className="fields">
            <input
              type="text"
              aria-label="nombre"
              placeholder="Nombre"
              name="name"
            />
            <input
              type="text"
              aria-label="correo"
              placeholder="Correo electrónico"
              name="email"
            />
            <input
              type="text"
              aria-label="mensaje"
              placeholder="Mensaje"
              name="message"
            />
            <div>
              <button
                className={`filled-button ${
                  !token && "filled-button-disabled"
                }`}
                type="submit"
                disabled={!token}
              >
                Enviar
              </button>
            </div>
            <NoBackModal show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </form>
        <img src={images.thanksTiger} alt="Bear" />
      </div>
    </div>
  );
}
