import AnchorLink from "react-anchor-link-smooth-scroll";
import { Button } from "react-bootstrap";
import { images } from "../assets/images/images";

export default function HelperButton() {
	return (
		<>
			{window.innerWidth > 992 ? (
				<AnchorLink href="#contact-form">
					<Button className="helper-button" variant="primary">
						¿Necesitas ayuda?
					</Button>
				</AnchorLink>
			) : (
				<AnchorLink href="#contact-form">
					<Button className="helper-button helper-mobile">
						<img src={images.mailIcon} alt="" />
					</Button>
				</AnchorLink>
			)}
		</>
	);
}
