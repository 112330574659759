import { useState } from "react";
import { CloseButton, Button, Modal } from "react-bootstrap";
import { images } from "../../../assets/images/images";
import FilledButton from "../../../components/FilledButton";
import InfoCard from "./InfoCard";
import user from "../../../assets/images/user.jpg";
import RankingDetail from "./RankingDetail";
import close from "../../../assets/icons/close.png";

function MyModal(props) {
	return (
		<Modal
			{...props}
			size="md"
			centered
			className="modal"
			contentClassName="modal-content"
		>
			{/* <Modal.Header closeButton>
				<Modal.Title> </Modal.Title>
			</Modal.Header> */}
			<Modal.Body className="body-modal">
				<Button className="close-modal" onClick={props.onHide}>
					<img src={close} alt="close modal" />
				</Button>
				<RankingDetail
					username={props.username}
					nickname={props.nickname}
					photo={props.photo}
					onHide={props.onHide}
				/>
			</Modal.Body>
		</Modal>
	);
}

export default function RankingCard(props) {
	const [modalShow, setModalShow] = useState(false);

	function getPlaceIcon(place) {
		if (place === "first") {
			return images.firstPlace;
		}
		if (place === "second") {
			return images.secondPlace;
		}
		if (place === "third") {
			return images.thirdPlace;
		}
	}

	return (
		<div className="ranking_card">
			<div className="user_info">
				<img
					className="user_place"
					src={getPlaceIcon(props.place)}
					alt=""
				/>
				<img
					className="user-photo"
					src={props.photo}
					alt="User photo"
				/>
				<div className="info_text">
					<div className="info_name">{props.username}</div>
					<div className="info_nickname">{props.nickname}</div>
					<img
						className="info_flat"
						src={images.argentina}
						alt="User flat"
					/>
				</div>
			</div>
			<div className="items">
				<InfoCard
					title="Fondo acumulado"
					subtitle="$ 14,585.05"
				></InfoCard>
				<InfoCard
					title="Rentabilidad lograda"
					subtitle="4000%"
				></InfoCard>
			</div>
			<div>
				<FilledButton
					text="Ver portafolio"
					onClick={() => setModalShow(true)}
				/>

				<MyModal
					username={props.username}
					nickname={props.nickname}
					photo={props.photo}
					show={modalShow}
					onHide={() => setModalShow(false)}
				/>
			</div>
		</div>
	);
}
