import { Row, Col } from "react-bootstrap";
import { images } from "../../assets/images/images";
import FilledButton from "../../components/FilledButton";
import StoreButton from "../../components/StoreButton";
import "../../css/home/intro.css";

export default function IntroSection() {
	return (
		<div className="main-section">
			<div className="bg-color">
				<Row className="main-row">
					{/* <Col lg={3}>
					<div className="avatar">
					<Image
							className="avatar-img"
							src={images.coolBull}
							fluid
							/>
							</div>
						</Col> */}
					<Col lg={7} md={12}>
						<div className="info">
							<div className="info-content">
								<h1>¡Aprende finanzas jugando!</h1>
								<p>
									Juega, aprende y compite en escenarios
									reales. Incrementa tus ganancias. Descubre
									productos de tu interés y logra tu bienestar
									financiero.
								</p>
							</div>
							<div>
								<FilledButton
									text="Empieza a jugar"
									url="https://alfi.la/"
								/>
							</div>
							<div className="info-stores">
								<a
									href="https://play.google.com/store/apps/details?id=com.alfinative&hl=es_PE&gl=US"
									target="_blank"
								>
									<img
										src={images.playStoreGray}
										alt="Play Store"
									/>
								</a>
								<a
									href="https://apps.apple.com/pe/app/alfi/id1497365055"
									target="_blank"
								>
									<img
										src={images.appleStoreGray}
										alt="App Store"
									/>
								</a>
								{/* <StoreButton
								store="App Store"
								text="Download on the"
								type="apple"
								></StoreButton>
							<StoreButton
							store="Google Play"
								text="Get it on"
								type="android"
							></StoreButton> */}
							</div>
						</div>
					</Col>
					<Col lg={5} md={12}>
						<div className="preview">
							<img src={images.alfiTeamMobile} />
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}
