import { images } from "../assets/images/images";

export default function SocialMedia() {
	return (
		<ul className="nav-social-media">
			<li>
				<a href="https://www.facebook.com/soyalfi" target="_blank">
					<img src={images.facebook} alt="Facebook Alfi" />
				</a>
			</li>
			<li>
				<a href="https://twitter.com/soy_alfi" target="_blank">
					<img src={images.twitter} alt="Twitter Alfi" />
				</a>
			</li>
			<li>
				<a
					href="https://www.instagram.com/alfinetworks"
					target="_blank"
				>
					<img src={images.instagram} alt="Instagram Alfi" />
				</a>
			</li>
			<li>
				<a
					href="https://www.youtube.com/channel/UC9yfoKJUT9ydsFshHuTU0SQ"
					target="_blank"
				>
					<img src={images.youtube} alt="Youtube Alfi" />
				</a>
			</li>
			<li>
				<a href="https://www.tiktok.com/@soy_alfi?" target="_blank">
					<img src={images.tiktok} alt="TikTok Alfi" />
				</a>
			</li>
		</ul>
	);
}
